<template>
  <div class="index_wrap">
    <h1 class="h_none">蓝灯鱼AI开放平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div style="height: 90px"></div>
    <section class="top">视频教程</section>
    <div class="tutorial_cen">
      <ul :class="['tutorial_title',{'item1': id == 1},{'item2': id == 2},{'item3': id == 3}]">
        <li :class="[{active: index == id - 1}]" @click="tab(item)" v-for="(item,index) in tutorial" :key="index">
          <span >
            {{item.text}}
          </span>
        </li>
      </ul>
      <div class="tutorial_shi">
        <video-player   class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="playerOptions"
                      @canplay="canplay($event)"
        ></video-player>
        <div class="directory">
          <ul class="directory_log">
            <li :class="[{'active': playId == item.id }]" @click="play(item)" v-for="(item,index) in catalog"
                :key="index">
              <span></span>
              视频：<span :title="item.title" class="name text-ellipsis title_info" style=" "> {{item.title}}</span>
              <span class="course-time">{{item.time}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
        <footers></footers>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import * as videojs from 'video.js'
  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼,商标查询，商标近似查询，商标图形查询，专利翻译，专利查询，专利检索'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是将人工智能技术运用到知识产权行业的平台。通过AI技术智能化商标查询,专利翻译,专利检索；提供在线使用和API接入等服务'
      }],
    },
    data() {
      return {
        //播放ID
        playId: '',
        id: 1,
        catalogList: [[], [], [], [], [], [], []],
        catalog: [],
        player: null,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4",
            src: ''//url地址
            // src: "" //url地址
          }],
          poster: "", //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        },
        tutorial: [
          {
            text: '商标查询',
            id: 1,
            show: true
          },
          {
            text: '商标监控',
            id: 2,
            show: false
          },
          {
            text: '法律文书',
            id: 3,
            show: false
          },
          {
            text: '专利翻译',
            id: 4,
            show: false
          },
          {
            text: '效率工具',
            id: 5,
            show: false
          },
          {
            text: '个人中心',
            id: 6,
            show: false
          },
          {
            text: 'AI引擎',
            id: 7,
            show: false
          }
        ]
      }
    },
    mounted() {
      this.getList()
    },

    components: {
      footers
    },
    methods: {
      getPreviewFilePath (fileUrl) {
        if(fileUrl){
          const TENANT_ID = 4
          return `/admin/sys-file/preview?url=${fileUrl}&isMin=false&TENANT-ID=${TENANT_ID}`
        }
        return fileUrl
      },
      //获取视频目录
      getList(item){
        this.$axios.get("/admin/videoinfo/list").then(({data}) => {
          if(data.code == 0){
              // this.catalogList = data.data
              data.data.map(item => {
                item.videoUrl = this.getPreviewFilePath(item.videoUrl)
                item.thumbnail = item.thumbnail
                if(item.type == 1){
                  this.catalogList[0].push(item)
                }else if(item.type == 2){
                  this.catalogList[1].push(item)
                }else if(item.type == 3){
                  this.catalogList[2].push(item)
                }else if(item.type == 4){
                  this.catalogList[3].push(item)
                }else if(item.type == 5){
                  this.catalogList[4].push(item)
                }else if(item.type == 6){
                  this.catalogList[5].push(item)
                }else if(item.type == 7){
                  this.catalogList[6].push(item)
                }
                // return item
              })
            //视频 sort 排序
            this.catalogList[0].sort((a, b) => {
              return a['sort'] - b['sort']
            })
            this.catalogList[1].sort((a, b) => {
              return a['sort'] - b['sort']
            })
            this.catalogList[2].sort((a, b) => {
              return a['sort'] - b['sort']
            })
            this.catalogList[3].sort((a, b) => {
              return a['sort'] - b['sort']
            })
            this.catalogList[4].sort((a, b) => {
              return a['sort'] - b['sort']
            })
            this.catalogList[5].sort((a, b) => {
              return a['sort'] - b['sort']
            })
            this.catalogList[6].sort((a, b) => {
              return a['sort'] - b['sort']
            })
            this.catalog = this.catalogList[0]
            this.playerOptions['sources'][0]['src'] = this.catalogList[0][0].videoUrl;
            this.playerOptions['poster'] = this.catalogList[0][0].thumbnail;
            this.playId = this.catalogList[0][0].id
          }
        })
      },
      tab(value) {
        this.tutorial.map(item => {
          if(value.id == item.id) item.show = true
          else item.show = false
          return item
        })
        this.id = value.id
        this.catalog = this.catalogList[value.id - 1]
      },
      //vjs_video_9578_html5_api
      // 切换id
      play(item){
        this.playId = item.id
        this.playerOptions['sources'][0]['src'] = item.videoUrl;
        this.playerOptions['poster'] = item.thumbnail;
        this.player.play();
      },
      //默认加载之后，就会执行该函数
      canplay(player){
        this.player = player;
        this.duration = player.duration();
      },
    }
  }
</script>


<style lang="less">
  .video-js .vjs-big-play-button{
    background: url("../../assets/images/tutorial/stop.png") no-repeat transparent center;
    background-color: transparent!important;
    border: none;
    span:nth-of-type(1)::before{
      content: none !important;
    }
  }
  .directory_log::-webkit-scrollbar {
    width: 4px
  }

  .directory_log::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px
  }

  .directory_log::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, .4)
  }

  .directory_log::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, .1)
  }
</style>


<style scoped lang="less">
  .index_wrap {
    .top {
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 158px;
      margin: 68px auto 0;
      margin-bottom: 51px;

      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 158px;
        height: 12px;
        background: rgba(38, 77, 216, 0.58);
        border-radius: 5px;
      }
    }

    .tutorial_cen {
      margin-bottom: 100px;
      .tutorial_title {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 1200px;
        height: 56px;
        background: #D8E0FF;
        border-radius: 12px 12px 0px 0px;
        position: relative;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 135px;
          height: 58px;
          font-weight: 600;
          color: #636D90;
          font-size: 18px;
          position: relative;
          &::after{
            width: 1px;
            height: 19px;
            position: absolute;
            content: '';
            display: block;
            right: 0;
            top: 18px;
            background:  #8E95CA
          }
          &:nth-last-of-type(1)::after{
            width: 0;
            height: 0;
          }
          &.active{
            background: #465CFC;
            color: #FFFFFF;
            border-radius: 12px 12px 0px 0px;
          }
        }
      }

      .tutorial_shi {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 0px 0 12px 12px;
        .video-player {
          width: 960px;
          height: 540px;
          margin: 0 auto;
        }
        .directory {
          width: 240px;
          height: 540px;
          background: #12102B;
          padding: 5px 2px;
          box-sizing: border-box;
          ul{
            height: 100%;
            overflow-y: auto;
            li{
              -webkit-text-size-adjust: 100%;
              font: 14px/1.5 "PingFang SC", "微软雅黑", "Microsoft YaHei", Helvetica, "Helvetica Neue", Tahoma, Arial, sans-serif;
              visibility: visible;
              line-height: 25px;
              box-sizing: border-box;
              text-decoration: none;
              outline: 0;
              padding: 0 5px;
              position: relative;
              font-size: 12px;
              color: #fff;
              cursor: pointer;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;

              &:hover {
                border-radius: 5px;
                color: #465CFC;
                background: #3C3F42;
              }

              &.active {
                color: #465CFC;
              }
            }
          }
        }
      }
    }

  }
</style>
